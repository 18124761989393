import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FooterComponent } from '../../components/footer/footer.component';
import { IonicModule } from '@ionic/angular';
import { AdSenseComponent} from '../../components/ad-sense/ad-sense.component';

@NgModule({
  declarations: [FooterComponent,AdSenseComponent],
  imports: [
    CommonModule,
    IonicModule,
  ],
  exports: [FooterComponent,AdSenseComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
